import React from 'react';
import { Layout, Seo } from 'components/common';
import { LearnMore } from 'components/landing';

const LearnMorePage = () => (
	<Layout>
		<Seo />
		<LearnMore />
	</Layout>
);

export default LearnMorePage;
